import React from 'react'
import {Link} from 'gatsby'
import Seo from "../components/seo"
import BlogLayout from "../components/BlogLayout"
import TechList from "../components/TechList"
import ProjectSnapshot from "../components/ProjectSnapshot"
import { StaticImage } from "gatsby-plugin-image"
import logos from "../components/CodingLogos"

import BlogFigure from '../components/BlogFigure'
import Figure1 from "../images/projects/img2ascii/Figure1.svg"
import Figure2 from "../images/projects/img2ascii/Figure2.svg"
import Figure3 from "../images/projects/img2ascii/Figure3.svg"

const project_IMG2ASCII = ()=>{
return (
    <BlogLayout>
        <Seo title="IMG2ASCII" />
        <h1>IMG2ASCII</h1>
        <ProjectSnapshot 
            projectName="img2ascii"
            projectType="Web App"
            description={`Turn an image into ASCII art in just a few simple steps. Easily swap between Image (Input) and ASCII (output) views, making adjustments to cropping, contrast, brightness, resolution, and font until the desired art is produced. Toggle between rendering light text on a dark background, or dark text on a light background.`}
            href="https://img-2-ascii.web.app"
            readableLink="img-2-ascii.web.app"
            langList={[
                (<img src={logos.progLang.html} alt="HTML" title="HTML" />),
                (<img src={logos.progLang.scss} alt="SCSS" title="SCSS" />),
                (<img src={logos.progLang.javascript} alt="JavaScript" title="JavaScript" />),
            ]}  
            frameworkList={[
                (<img src={logos.frameworks.firebase} alt="Firebase" title="Firebase" />),
            ]}
            previewImages={[
                (<StaticImage src="../images/projects/img2ascii/preview01.png" alt="preview01" width={200} key="0" />),
                (<StaticImage src="../images/projects/img2ascii/preview02.png" alt="preview02" width={200} key="1" />),
                (<StaticImage src="../images/projects/img2ascii/preview03.png" alt="preview03" width={200} key="2" />),
                (<StaticImage src="../images/projects/img2ascii/preview04.png" alt="preview04" width={200} key="3" />),
            ]}
        />
        <div className="blog-text-column">
            {/* TODO: NEW HEADING + TABLE OF CONTENTS */}
            <h2>Introduction</h2>

            <h3>What is ASCII Art?</h3>
            <p>ASCII Art is the practice of constructing an image using only the Graphical <a href="https://en.wikipedia.org/wiki/ASCII">ASCII</a> text characters. While there are many ways in which someone might create images using text, IMG2ASCII is concerned only with recreating existing digital images.</p>

            <h3>How does it Work?</h3>
            <p>
                A conventional digital image consists of a grid of pixels. Each pixel providing Colour and Tonal information for a given unit area of the image. Our ASCII Art consists of a grid of monospace (fixed-width) ASCII characters, representing the tonal information of the original image through nothing more than regions of high/low pixel density. This is an application of <a href="https://en.wikipedia.org/wiki/Dither">Dithering</a>, taking advantage of the fact that humans will perceive a small collection of black and white pixels as a unified mixture or average grey.
            </p><p>
                Simply put, we map the <b>brightness/tone</b> of each pixel in an image to the <b>pixel density</b> of an ASCII character. A higher density of black pixels on a white background will be perceived as darker, and conversely, a higher density of white pixels on a black background will be perceived as lighter/brighter. The core mechanism is quite basic, however, in practice there a number of difficulties in producing Good ASCII Art.
            </p>
            {/* TODO: CALCULATING DENSITY WITH CANVAS */}

            <h2>Creating Good ASCII Art</h2>
            <p>
                ASCII Art is a heavy form of image compression, meaning creating ASCII art from photos can be a tricky business. In the process we lose information, reducing the Spatial and Tonal Resolution of the original image. Hence, to create ASCII Art which contains a pronounced and recognizable subject, we will probably need to adjust our input image to compensate for these effects in the ASCII output. Using a few tricks, we can keep the information that's important, and throw away the rest.
            </p>

            <h3>Reduced Tonal Resolution</h3>
            <p>
                Our Input images are typically stored as a series of RGB (Red, Green, Blue) values, with 8-bit colour depth on each colour channel. This means the colour of each pixel is created by combining a Red, Green, and Blue light source by different amounts (according to these RGB values). These are 8-bit values, meaning there are 2<sup>8</sup> = 256 possible brightness values for each colour channel, resulting in 2<sup>8</sup> x 2<sup>8</sup> x 2<sup>8</sup> = (2<sup>8</sup>)<sup>3</sup> = 16 777 216 unique colours.
            </p><p>
                However, our ASCII art does not have colour and only concerns itself with the brightness of a given pixel. So how do we extract this brightness from the RGB values we have for each pixel of our input image? Well, one way is to convert from an RGB representation of colour to a HSL (Hue, Saturation, Lightness) representation (see <a href="https://en.wikipedia.org/wiki/HSL_and_HSV">HSL and HSV</a>). Then we can simply use the Lightness value and throw away the Hue and Saturation values. In doing so, you'll see that we now only have 8-bits (256) values of lightness. We then have to map these lightness values to the nearest density values of the 95 graphic ASCII characters available to us in our ASCII art output.
            </p><p>
                Here we reach our first point of difficulty. If we create create a list of the pixel densities of each of our ASCII characters, we see that the maximum density achieved by the set of characters available to us is 0.1213 ('@' character for the <pre className='inline code'>Roboto Monospace</pre> font). In other words, only the bottom 12.13% of the lightness values achievable in HSL pixels would map directly to a corresponding density/ASCII character (See Figure 1 below). Furthermore, the densities of many characters are very similar, so for a given lightness value, multiple ASCII characters would achieve the same result (notice the bin counts of Figure 1) leaving us with even fewer distinct lightness values in our final image.
            </p>

            <BlogFigure src={Figure1} label="Figure 1: ASCII Density Spread (Histogram)"/>

            <p>
                If we were to map our lightness values to characters at this stage, only the darkest areas of the image would be resolved in the ASCII output. If we want to make ASCII art that can be deciphered into recognisable subjects, we need to modify this mapping process.
            </p>

            <h4>Contrast Compression</h4>
            <p>
                A Naïve solution would be to multiply the density score for each character by some factor. Think of this as either stretching the ASCII densities to fit the range of image lightness values, or conversely, squashing the image lightness values to fit within the bounds of the ASCII densities (See Figure 2). Effectively, this is equivalent to reducing the tonal contrast of the input image.
            </p>
            <div className="note pad">
                NOTE: <em>By Stretching the densities instead of compressing the pixel values, we only need to perform this calculation once, rather than repeating the calculation for each of the many pixels in the input image.</em>
            </div>
            <BlogFigure src={Figure2} label="Figure 2: ASCII Density Spread (Histogram) - Character Densities multiplied by a Factor"/>
            <p>
                If we want to stretch such that highest ASCII density is now equal to a full density of 1, we multiply each character density by a value of <pre className='inline code'>1 ÷ max_character_density</pre>.
            </p><p>
                This works fairly well as a general solution, but as we can see from Figure 2, there are lot of voids in possible density/lightness values, and this creates obnoxious visible banding where our original image had smooth gradients of tone.
            </p>

            <h4>Shifting Brightness</h4>
            <p>
                Often, most of the important details of an image will be in the middle tones. To Solve this banding, rather than having our densities stretched across the whole tonal space, instead we stretch it over a smaller space somewhere in the middle. This will effectively give us an upper and lower threshold to which pixels that are brighter and darker will be grouped or clipped. You could think of this as creating finer bands in the middle tones, and two large bands in the bright and dark tones. In practice, we multiply the density scores by some factor (stretching) and add some constant (shifting the position of our region of detailed tonal mapping). To centre this tonal region around some bias value, we can calculate the constant as <pre className='inline code'>bias - (factor × max_character_density ÷ 2)</pre>. If want our tonal region exactly in the centre, we use a bias value of 0.5.
            </p>
            <BlogFigure src={Figure3} label="Figure 3: ASCII Density Spread (Histogram) - Character Densities multiplied by a Factor, and shifted by a Constant"/>
            <p>
                <em>In the IMG2ASCII App, the value of this <b>Factor</b> and <b>Constant</b> are defined by the <b>Contrast</b> and <b>Brightness</b> controls.</em>
            </p>
            
            <h3> Reduced Pixel Dimensions </h3>
            <p>
                A single character will inhabit a relatively large number of pixels (area) on the screen. So, if we do a 1 to 1 mapping of pixel to character in the output, the resulting ASCII art will likely be too large to be useful, or even be able to fit on the screen for that matter. We can reduce the size of the font in the ASCII art to combat this, but this will only take us so far while retaining the charm of readable text characters. To Effectively deal with this issue, we need to reduce the Pixel Dimensions (Resolution) of the input image. However, a balance must be met. Reduce the resolution by too much, and details required to perceive the subject matter of the image might just be compressed into oblivion. Leave the resolution too high, and small details may be visible, but only if you have a large enough screen that each line of text isn't forced to wrap onto the next line, completely ruining the presentation of the image. 
            </p><p>
                This all depends on what aspects of the image you want to be seen, and where you are going to be using the resulting ASCII Art. Hence, this pixel dimension scaling is controlled by the user under the <b>Scale</b> control.
            </p>
            {/* TODO: PRESENT TWO OPTIONS FOR REDUCING RESOLUTION */}
        </div>
        <button><Link to="/">Back Home</Link></button>
    </BlogLayout>
)
}

export default project_IMG2ASCII