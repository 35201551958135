import React from "react";

const BlogFigure = ({src, label})=>{
    return (
        <div className="blogFigure">
            <img src={src} alt={label} />
            <span>{label}</span>
        </div>
    )
}

export default BlogFigure